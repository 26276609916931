import { MutableRefObject, useEffect, useMemo } from 'react';

const wrapper = (ref: MutableRefObject<HTMLElement>, handler: () => any) => (event: Event) => {
  if (ref && ref.current && !ref.current.contains(event.target as Node)) {
    handler();
  }
};

export const useOnOutsideClick = (ref: MutableRefObject<HTMLElement>, handler: () => any) => {
  const callback = useMemo(() => wrapper(ref, handler), [ref.current, handler]);
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    document.addEventListener('click', callback); // mouse users
    document.addEventListener('touchend', callback); // touch device users
    return () => {
      document.removeEventListener('click', callback);
      document.removeEventListener('touchend', callback);
    };
  }, [callback]);
};
